//to ignore cli warning about deprecated mixin
$enable-deprecation-messages: false;
$ignore-warning             : true;

// Custom class not bootstrap
$fn:'HelveticaNeue'; // fn = font normal 
$fm:'HelveticaNeueMed'; // fm = font Medium 
$border-radius: 0.75rem;
// Body
$body-bg   : #F4F6FA;
$body-color: #7e7e7e;

// Typography
$font-family-base: 'Roboto',
sans-serif;
$headings-font-weight   : 500;
// $headings-font-family:        $fm;




$font-size-base     : 0.875rem !default;
$font-weight-base   : 400 !default;
// $line-height-base: 1.6;
$h1-font-size       : 2.25rem;
$h2-font-size       : 1.875rem;
$h3-font-size       : 1.5rem;
$h4-font-size       : 1.125rem;
$h5-font-size       : 1rem;
$h6-font-size       : 0.938rem;


//
// Color system
//
$white   : #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black   : #000 !default;


$blue  : #5e72e4;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink  : #e83e8c !default;
$red   : #EE3232;
$orange: #ff9900 !default;
$yellow: #FFFA6F;
$green : #297F00;
$teal  : #20c997 !default;
$cyan  : #3065D0;



$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge((
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900),
  $grays);


$muted     : #89879f;
$text-muted: #89879f;
$mine-shaft: #262626;
$ebony-clay: #232833;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge((
	  "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800),
  $colors);


  
$primary  : #4285f4 !default;
$secondary: #e9b536 !default;
$success  : #2BC155 !default;
$info     : #2421DA !default;
$warning  : #FF6D4D !default;
$danger   : #FF4847 !default;
$light    : #F4F5F9 !default;
$dark     : #B1B1B1;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(("primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark),
  $theme-colors);


// $base-font-size   : 0.8125rem !default;
$heading-primary-size: 1.8rem;
$sub-heading-size    : 1.6rem !default;

$grid-gutter-width: 30px;

$dropdown-lik-color: $body-color;


$border-color  : #EEEEEE;
$headings-color:#3d4465 !default;
$rounded : 0.75rem ;



$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440);

$strong:#646c9a;
$border: #f0f1f5;
$shadow: 0px 0Epx 40px 0px rgba(82,63,105,0.1);
$body-bg: #F4F6FA;


///////////////
// Solid Color
///////////////
$white: #fff;
$black: #000;
$charade: #23252F;

/////////////////////////
// Dark Version Color
/////////////////////////
$d-ctd: #ddd;
$d-ctl: #828690;
$d-border: #333a54;
$d-bg: #181f39;
$dark-card: #1e2746;
$dark_bg_lighter: #1E2A4A;

/////////////////////////
// Primary Light
/////////////////////////
$primary-light: lighten($primary, 25%);
$secondary-light: lighten($secondary, 36%);
$success-light: lighten($success, 47%);
$warning-light: lighten($warning, 30%);
$danger-light: lighten($danger, 27%);
$info-light: lighten($info, 40%);
$dark-light: lighten($dark, 25%);

/////////////////////////
// Opacity 
/////////////////////////
$primary-opacity: rgba($primary, 0.2);
$secondary-opacity: rgba($secondary, 0.5);
$success-opacity: rgba($success, 0.1);
$warning-opacity: rgba($warning, 0.1);
$danger-opacity: rgba($danger, 0.15);
$info-opacity: rgba($info, 0.1);
$dark-opacity: rgba($dark, 0.35);

////////////////////////
// Light Version Color
////////////////////////
$l-ctd: #464a53;
$l-ctl: #828690;
$l-border: #eaeaea;
$l-bg: #f2f4fa;
$heading: #333;

///////////////////
// Material Colors
///////////////////
$dusty-gray: #999999;
$gallery: #EEEEEE;
$gray: #898989;
$input-border-color: $border;


///////////////////
// Social Colors
///////////////////
$facebook: #3b5998;
$twitter: #1da1f2;
$youtube: #FF0000;
$google-plus: #db4439;
$linkedin: #007bb6;
$instagram: #c32aa3;
$pinterest: #bd081c;
$google: #4285f4;
$snapchat: #fffc00;
$whatsapp: #25d366;
$tumblr: #35465d;
$reddit: #ff4500;
$spotify: #1ed760;
$yahoo: #430297;
$dribbble: #ea4c89;
$skype: #00aff0;
$quora: #aa2200;
$riverBed: #4C585C;
$vimeo: #1ab7ea;

//utility variables
$radius: 1.25rem; 
