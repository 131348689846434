@import './variables.scss';

.copiger-badge{
    line-height: 1.5;
    border-radius: $radius;
     font-size: 14px;
    font-weight: 600;
    padding: 4px 10px;
	border:1px solid transparent;
}

.copiger-badge-rounded{
    border-radius: 20px;
    padding:3px 13px ;
}
.copiger-badge-circle{
    border-radius: 100px;
    padding: 3px 7px;
}

.copiger-badge-outline-primary{
    border: 1px solid $primary;
    color: $primary;
}
.copiger-badge-outline-secondary{
    border: 1px solid $secondary;
    color: $secondary;
	@at-root [data-theme-version="dark"] & {
		color:$body-color;
	}
}
.copiger-badge-outline-success{
    border: 1px solid $success;
    color: $success;
}
.copiger-badge-outline-info{
    border: 1px solid $info;
    color: $info;
}
.copiger-badge-outline-warning{
    border: 1px solid $warning;
    color: $warning;
}
.copiger-badge-outline-danger{
    border: 1px solid $danger;
    color: $danger;
}
.copiger-badge-outline-light{
    border: 1px solid $border;
    color: $dark;
	@at-root [data-theme-version="dark"] & {
		color:$body-color;
	}
}
.copiger-badge-outline-dark{
    border: 1px solid $dark;
    color: $dark;
	@at-root [data-theme-version="dark"] & {
		color:$body-color;
	}
}



.copiger-badge-xs {
    font-size: 10px;
    padding: 0px 5px;
    line-height: 18px;
}
.copiger-badge-sm {
	font-size: 11px;
    padding: 5px 8px;
    line-height: 11px;
}
//.copiger-badge-md {
//    font-size: 13px;
//    padding: 0px 9px;
//    line-height: 25px;
//}
.copiger-badge-lg {
    font-size: 14px;
    padding: 0px 10px;
    line-height: 30px;
}


.copiger-badge-xl {
    font-size: 15px;
    padding: 0px 15px;
    line-height: 35px;
}
.copiger-badge-default{
    background: #ADB6C7;
}
.copiger-badge-success {
    background-color: $success;
}
.copiger-badge-info {
    background-color: $info;
}
.copiger-badge-primary {
    background-color: $primary;
}
.copiger-badge-warning {
    background-color: $warning;
}
.copiger-badge-danger {
    background-color: $danger;
}
.copiger-badge-dark {
    background-color: $dark;
}
.copiger-badge-light {
    background-color: $light;
}



.light-copiger-badge-default{
    background: #ADB6C7;
}
.light-copiger-badge-success {
    background-color: $success-light;
	color:$success;
	@at-root [data-theme-version="dark"] & {
		background-color: $success-opacity;
	}
}
.light-copiger-badge-info {
    background-color: $info-light;
	color:$info;
	@at-root [data-theme-version="dark"] & {
		background-color: $info-opacity;
	}
}
.light-copiger-badge-primary {
    background-color: $primary-light;
	color:$primary;
	@at-root [data-theme-version="dark"] & {
		background-color: $primary-opacity;
	}
}
.light-copiger-badge-secondary {
    background-color: $secondary-light;
	color:$secondary;
	@at-root [data-theme-version="dark"] & {
		background-color: $secondary-opacity;
		color:$body-color;
	}
}
.light-copiger-badge-warning {
    background-color: $warning-light;
	color:$warning;
	@at-root [data-theme-version="dark"] & {
		background-color: $warning-opacity;
	}
}
.light-copiger-badge-danger {
    background-color: $danger-light;
	color:$danger;
	@at-root [data-theme-version="dark"] & {
		background-color: $danger-opacity;
	}
}
.light-copiger-badge-dark {
    background-color: $dark-light;
	color: #343a40;
	@at-root [data-theme-version="dark"] & {
		background-color: $dark-opacity;
		color:$body-color;
	}
}