$main-color: #4285f4;
$optional-color: #777777;
$black-color: #000000;
$white-color: #ffffff;
$transition: .5s;
$fontfamily: Montserrat;

/* Admin top navbar */

html {
  overflow-x: hidden;
}

.admin-top-navbar {
  background: $white-color;
  box-shadow: 0px 0 5px rgba(0, 0, 0, 0.08);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  height: 4rem;
  padding: 15px 35px;

  .navbar-nav {
    display: block;
    .nav-item {
      display: inline-block;
      position: relative;
      padding: {
        left: 20px;
        right: 20px;
        top: 20px;
        bottom: 15px;
      }
      a {
        color: $black-color;
        text-transform: capitalize;
        position: relative;
        font: {
          weight: 600;
          size: 15px;
        }
        padding: {
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }

        i {
          font-size: 18px;
          position: relative;
          top: 2px;
          display: inline-block;
          margin-left: -2px;
          margin-right: -2px;
        }
      }
      .dropdown-menu {
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
        background: $white-color;
        position: absolute;
        top: 80px;
        left: auto;
        right: 0;
        border: none;
        display: block;
        width: 200px;
        z-index: 99;
        opacity: 0;
        border-radius: 5px;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        padding: {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        li {
          position: relative;
          padding: 0;
          display: block;

          a {
            display: block;
            font-size: 14px;
            color: $black-color;

            border-bottom: 1px dashed #eee;
            padding: 15px 15px;

            &::before {
              display: none;
            }
            &:hover, &:focus, &.active {
              color: $main-color;
            }
          }
          .dropdown-menu {
            position: absolute;
            left: 220px;
            top: 0;
            opacity: 0 !important;
            visibility: hidden !important;

            li {
              a {
                color: $black-color;

                &:hover, &:focus, &.active {
                  color: $main-color;
                }
              }
            }
          }
          &:hover {
            .dropdown-menu {
              opacity: 1 !important;
              visibility: visible !important;
              top: -20px !important;
            }
          }
        }
      }
      &:hover {
        ul {
          opacity: 1;
          visibility: visible;
          top: 100%;
        }
      }

      &.profile {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }

      &.add-products {
        padding-top: 0;
        padding-bottom: 0;
        a {
          background-color: $main-color;
          color: $white-color;
          padding: 10px 20px;
          border-radius: 5px;
          font-size: 14px;
          &:hover {
            background-color: $black-color;
          }
        }
      }

      &.notification {
        .count-info {
          position: relative;
          .circle {
            position: absolute;
            background: $main-color;
            color: #fff;
            height: 10px;
            width: 10px;
            text-align: center;
            border-radius: 50%;
            line-height: 22px;
            font-size: 10px;
            top: -2px;
            right: -4px;
            z-index: 1;
            .ripple {
              background-color: $main-color;
              position:absolute;
              width: 22px;
              height: 22px;
              z-index: -1;
              right: -6px;
              top: -6px;
              opacity:0;
              margin:0;
              border-radius:100px;
              -webkit-animation:ripple 2.8s infinite;
              animation:ripple 2.8s infinite;

              &:nth-child(2) {
                animation-delay:.6s;
                -webkit-animation-delay:.6s
              }
              &:nth-child(3) {
                animation-delay:.9s;
                -webkit-animation-delay:.9s
              }
            }
            @-webkit-keyframes ripple {
              0% {
                opacity:1;
                -webkit-transform:scale(0);
                transform:scale(0);
              }
              100% {
                opacity:0;
                -webkit-transform:scale(1);
                transform:scale(1);
              }
            }
            @keyframes ripple {
              0%{
                opacity:1;
                -webkit-transform:scale(0);
                transform:scale(0);
              }
              100%{
                opacity:0;
                -webkit-transform:scale(1);
                transform:scale(1);
              }
            }
          }
        }
        .dropdown-menu {
          width: 250px;
          li {
            a {
              padding: 15px 20px;
            }
          }
        }
        a {
          font-size: 15px;
          .status {
            font-weight: 600;
            margin-right: 5px;
          }
          .time {
            font-size: 12px;
            color: $optional-color;
          }
          p {
            margin: 0;
            font-size: 13px;
          }
        }
        .feeds {
          color: $main-color;
          font-weight: 600;
          text-align: center;
          display: block;
        }
      }
    }
  }

  .burger-menu {
    padding-right: 15px;
    cursor: pointer;
    transition: all .50s ease-in-out;
    -webkit-transition: all .50s ease-in-out;
    display: none;
    span {
      height: 1px;
      width: 25px;
      background: #303030;
      display: block;
      margin: 6px 0;
      transition: all .50s ease-in-out;
      -webkit-transition: all .50s ease-in-out;
    }
  }
}

/* Admin sidebar */
.admin-sidebar {
  background: #fff;
  border-top: 1px solid #f6f6f7;
  position: fixed;
  height: 100%;
  width: 14rem;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.05);
  z-index: 8;
  left: 0;
  top: 4rem;
  padding-top: 20px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      a {
        padding: 15px 30px;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        border-radius: 30px 0px 0px 30px;
        i {
          margin-right: 8px;
          font-size: 1em;
        }

        &.active {
          color: $main-color;
          background: #eeeef8;
        }
      }
    }
  }
}

/* Admin main content */
.admin-main-content {
  background-color: #eeeef8;
  width: 100%;
  margin-top: 4rem;
  transition: all .50s ease-in-out;
  padding: 30px 30px 0;
  position: relative;
  min-height: calc(100vh - 62px);
  padding-left: 250px;
  overflow: hidden;

  .page-header {
    border-bottom: 1px dashed #d9d9d9;
    margin-bottom: 20px;
    padding-bottom: 10px;
    h2 {
      font-size: 20px;
      margin: 0;
    }
  }
}

/* Admin products */
.admin-products {
  .single-products-box {
    background-color: $white-color;
    padding: 20px;

    .products-image {
      .products-button {
        ul {
          li {
            transform: translateX(0);
            opacity: 1;
            visibility: visible;
            a {
              i {
                background-color: $main-color;
                color: $white-color;
                height: 30px;
                width: 30px;
                line-height: 30px;
                text-align: center;
                border-radius: 50%;
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    .products-content {
      .price {
        opacity: 1 !important;
        visibility: visible !important;
      }
    }
  }
}
.admin-pagi {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 0;
  &::before {
    display: none;
  }

  .page-numbers {
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    margin: 0 3px;
    font-size: 15px;

    &.current {
      background-color: $main-color;
      color: $white-color;
    }

    &.prev, &.next {
      font-size: 25px;
      top: 6px;
      margin-left: 3px;
      margin-right: 3px;
      padding: 0;
      line-height: 38px;
    }
  }
}

/* Admin product modal */
.admin-product-modal {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: $transition;

  .modal-innter-content {
    width: 100%;
    background-color: #ffffff;
    height: 100%;
    margin-left: auto;
    overflow-y: auto;
    margin-right: -100%;
    transition: $transition;

    .modal-body {
      padding: 40px;
      h3 {
        margin-bottom: 25px;
        padding-bottom: 5px;
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        font: {
          size: 20px;
          weight: 600;
        };
        &::before {
          width: 50px;
          height: 1px;
          bottom: -1px;
          content: '';
          position: absolute;
          left: 0;
          background-color: $main-color;
        }
      }

      form {
        label {
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 10px;
        }

        .title {
          font-size: 18px;
        }
        .upload-img {
          position: relative;
          border: 2px dashed #eee;
          border-radius: 5px;
          background: #f7f9fb;
          transition: all 0.3s linear;
          text-align: center;
          cursor: pointer;

          span {
            display: block;
            font-weight: 600;
            padding-top: 50px;
            padding-bottom: 50px;
            font-size: 14px;
            i {
              display: block;
              font-size: 50px;
              margin-bottom: 15px;
              color: #5a5757;
            }
          }

          input {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
            cursor: pointer;
            opacity: 0;
          }
        }
        .uploaded-img {
          width: 100px;
          margin-top: 15px;
        }
        .btn {
          text-transform: capitalize;
        }
        .modal-btn {
          overflow: hidden;
          margin-top: 30px;
        }
      }
    }

    .view-product-content {
      .product-img {
        position: relative;

        .sale-tag {
          position: absolute;
          left: 20px;
          top: 20px;
          background-color: red;
          color: #ffffff;
          width: 55px;
          height: 55px;
          line-height: 55px;
          text-align: center;
          border-radius: 50%;
          z-index: 2;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 14px;
        }
        .new-tag {
          position: absolute;
          left: 20px;
          top: 20px;
          background-color: green;
          color: #ffffff;
          text-align: center;
          z-index: 2;
          padding: 1px 7px;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 14px;
        }
        .discount {
          position: absolute;
          left: 20px;
          top: 20px;
          background-color: red;
          color: #ffffff;
          text-align: center;
          z-index: 2;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 14px;
          padding: 6px 15px;
        }
      }

      .products-details-desc {
        h3 {
          border: none;
          margin: 0 0 10px;
          &::before {
            display: none;
          }
        }
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .modal-innter-content {
      margin-right: 0;
    }
  }

  .close {
    color: $black-color;
    font-size: 28px;
    outline: 0 !important;
    line-height: initial;
    transition: $transition;
    position: relative;
    right: 40px;
    top: 37px;
    z-index: 1;

    &:not(:disabled):not(.disabled){
      &:hover, &:focus{
        color: red;
        text-decoration: none;
        opacity: 1;
      }
    }
  }

  &.view-product {
    .modal-innter-content {
      width: 1000px;
    }
  }
}

/* Admin table */
.admin-table {
  background-color: $white-color;
  margin-bottom: 30px;

  .title {
    font-size: 18px;
    padding: 15px 15px 0;
  }

  table {
    td, th {
      border-top: 1px solid #f5f5f5;
      vertical-align: middle;
      padding: 15px 20px;
    }
    td {
      font-size: 14px;
      .badge {
        padding: 5px 10px;
      }
    }
    .customer, .product {
      img {
        border-radius: 50%;
        height: 30px;
        width: 30px;
        margin-right: 8px;
      }
    }
  }

  &.height-555 {
    height: 555px;
    overflow-y: auto;
  }
}
.badge_warning {
  background-color: rgba(255, 190, 11, 0.2);
  color: #ffbe0b;
}
.badge_success {
  color: #00c9a7;
  background: rgba(0, 201, 167, 0.1);
}
.badge_danger {
  background-color: rgba(255, 92, 117, 0.2);
  color: #ff5c75;
}
.admin-orders-table {
  table {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
}

/* Stats card */
.stats-card {
  background-color: $white-color;
  /*border: 2px solid #067bfd;*/
  margin-bottom: 30px;
  padding: 25px;
  border-radius: 4px;

  h3 {
    font-size: 18px;
    margin: 0;
  }
  p {
    color: #686c71;
    margin: 0;
    font-size: 13px;
  }
  .avatar {
    font-size: 30px;
    text-align: center;
    background: #f1f2f3;
    color: #fff;
    white-space: nowrap;
    position: relative;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    display: inline-block;
  }
  .growth {
    font-size: 19px;
    font-weight: 600;
    margin: 20px 0 0;

    .badge {
      font-size: 12px;
      float: right;
      font-weight: 500;
      padding: 5px 8px;
    }
  }
}

.stats-card-default{
  border: 2px solid #2c3e50;
}

.stats-card-primary{
  border: 2px solid #3498db;
}

.stats-card-success{
  border: 2px solid #2ecc71;
}

.stats-card-danger{
  border: 2px solid #e74c3c;
}

.stats-card-warning{
  border: 2px solid #e67e22;
}

.stats-card-gold{
  border: 2px solid #ffc107;
}

.avatar-default {
  color : #2c3e50 !important;
  background: rgba(44, 62, 80, 0.1) !important;
}

.avatar-primary {
  color : #3498db !important;
  background: rgba(52, 152, 219, 0.1) !important;
}

.avatar-success {
  color :#2ecc71 !important;
  background: rgba(46, 204, 113, 0.1) !important;
}

.avatar-danger {
  color : #e74c3c !important;
  background: rgba(231, 76, 60, 0.1) !important;
}

.avatar-warning {
  color : #e67e22 !important;
  background: rgba(225, 190, 159, 0.1) !important;
}

.avatar-dark{
  color : #2d3436 !important;
  background: rgba(45, 52, 54, 0.1) !important;
}

.avatar-blue {
  color: $main-color !important;
  background: rgba(63, 135, 245, 0.1) !important;
}

.avatar-cyan {
  color: #00c9a7 !important;
  background: rgba(0, 201, 167, 0.1) !important;
}

.avatar-gold {
  color: #ffc107 !important;
  background: rgba(255, 193, 7, 0.1) !important;
}

.avatar-pink {
  color: #ff7675 !important;
  background: rgba(255, 218, 217, 0.1) !important;
}

.avatar-purple {
  color: #886cff !important;
  background: rgba(136, 108, 255, 0.1) !important;
}

.charts-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: -10px;
  margin-left: -10px;
}

.chart-box {
  background-color: $white-color;
  border-radius: 4px;
  flex: 1;
  margin: 0 10px;
}

/* Chart box */
.chart-box {
  background-color: $white-color;
  padding: 25px;
  border-radius: 4px;
  margin-bottom: 30px;

  .header {
    h3 {
      margin: 0;
      font-size: 18px;
    }
  }
}

/* Admin signin */
.admin-signin {
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: center center;

  .login-content {
    background-color: #fff;
    border: 1px solid #eee;
    padding: 40px 30px;
    max-width: 600px;
    margin: auto;
    box-shadow: 0 0 30px #eee;
    border-radius: 10px;

    h2 {
      text-align: center;
    }
  }
}
.loading {
  text-align: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  h1 {
    margin: 0;
    font-size: 20px;
  }
}


/* Responsive CSS */
@media only screen and (max-width: 767px) {
  .admin-signin {
    height: 100%;
  }
  .admin-top-navbar {
    padding: 10px 20px;
    .navbar-brand {
      display: none;
    }
    .navbar-nav {
      .nav-item {
        padding-left: 10px;
        padding-right: 10px;

        &.add-products {
          a {
            padding: 10px 15px;
            font-size: 12px;
          }
        }
      }
    }
  }
  .admin-sidebar {
    top: 70px;
    left: -100%;
    transition: .5s;
    &.active {
      left: 0;
    }
  }
  .admin-main-content {
    margin-top: 70px;
    padding: 30px 15px 0;
    padding-left: 15px;
  }
  .stats-card {
    h3 {
      font-size: 16px;
    }
    .growth {
      font-size: 15px;
    }
  }
  .admin-table table td, .admin-table table th {
    padding: 10px 15px;
    font-size: 13px;
  }
  .burger-menu {
    display: block !important;
  }
  .admin-product-modal {
    .modal-innter-content {
      width: 100%;
      .modal-body {
        padding: 30px;
      }
    }

    .close {
      right: 30px;
      top: 28px;
    }
  }

  .admin-product-modal.view-product {
    .modal-innter-content {
      width: 100%;
    }
  }
}

.btn-main {
  color: $white-color;
  background-color: $main-color;
  border-color: $main-color;

  line-height: 1.5;
  border-radius: 0 !important;

  &:hover {
    background-color: transparent;
    color: $black-color;
    border-color: $black-color;
  }
}


@media only screen and (max-width: 575px) {

}

@media only screen and (min-width: 576px) and (max-width: 767px) {

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-products-box {
    .products-content {
      h3 {
        font-size: 15px;
      }
      .price {
        font-size: 13px;
      }
    }
  }
  .admin-sidebar {
    top: 70px;
    left: -100%;
    transition: .5s;
    &.active {
      left: 0;
    }
  }
  .admin-main-content {
    padding-left: 30px;
  }

  .admin-top-navbar {
    .burger-menu {
      padding-right: 15px;
      padding-left: 15px;
      display: block;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .stats-card {
    padding: 18px;
    text-align: center;
    h3 {
      font-size: 15px;
    }
    .media {
      display: block;
    }
    .avatar {
      font-size: 20px;
      width: 35px;
      height: 35px;
      line-height: 35px;
      margin-top: 10px;
    }
    .growth {
      .badge {
        float: inherit;
        margin-top: 10px;
        display: block;
        padding: 8px 8px;
      }
    }
  }
  .single-products-box {
    .products-content {
      h3 {
        font-size: 15px;
      }
      .price {
        font-size: 13px;
      }
    }
  }
}

.product-img {
  max-height: 55px;
  width: 100%;
}


.prodcut-show {
  height: 400px;
  width: 100%;
}

.woocommerce-widget-area .collections-list-widget .collections-list-row li a {
  text-transform: uppercase;
}

.button-export-data {
  background-color: #fff000;
  border-radius: 12px;
  color: #000;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 15px;
  text-align: center;
  transition: 200ms;
  box-sizing: border-box;
  border: 0;
  font-size: 16px;
  font-family: "Montserrat";
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:not(:disabled) {
    &:hover, 
    &:focus {
      outline: 0;
      background: #f4e603;
      box-shadow: 0 0 0 2px rgba(0,0,0,.2), 0 3px 8px 0 rgba(0,0,0,.15);
    }
  }
  
  &:disabled {
    filter: saturate(0.2) opacity(0.5);
    -webkit-filter: saturate(0.2) opacity(0.5);
    cursor: not-allowed;
  }

  &::before {
    content: '\ea86'; // Replace this with the Unicode value for the specific Boxicon you want
    font-family: 'boxicons'; // Use the Boxicons font-family value
    font-weight: normal; // Boxicons are typically normal weight
    margin-right: 8px; // Add some space between the icon and the text
  }
}

.table {
  font-family: $fontfamily;
}